
import { defineComponent, ref, computed, watchEffect } from 'vue';
import { PRIVILEGE, PRIVILEGE_NAME } from '@/common/constant';

import Select from '@/components/ui/Select.vue';

export default defineComponent({
  name: 'PrivilegeSelect',
  components: { Select },
  props: {
    modelValue: {
      type: String, // 'PRIMARY_OWNER' | 'OWNER' | 'MANAGER' | 'MEMBER' | 'RESTRICTED'
      default: PRIVILEGE.member
    },
    accountPrivilege: String
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const options = ref([
      // v1.0 ではスコープ外
      // {
      //   id: PRIVILEGE.primary,
      //   icon: 'privilege-primary',
      //   label: PRIVILEGE_NAME[PRIVILEGE.primary],
      //   value: PRIVILEGE.primary
      // },
      {
        id: PRIVILEGE.owner,
        icon: 'privilege-owner',
        label: PRIVILEGE_NAME[PRIVILEGE.owner],
        value: PRIVILEGE.owner
      },
      {
        id: PRIVILEGE.admin,
        icon: 'privilege-admin',
        label: PRIVILEGE_NAME[PRIVILEGE.admin],
        value: PRIVILEGE.admin
      },
      {
        id: PRIVILEGE.member,
        icon: 'privilege-member',
        label: PRIVILEGE_NAME[PRIVILEGE.member],
        value: PRIVILEGE.member
      },
      {
        id: PRIVILEGE.restricted,
        icon: 'privilege-restricted',
        label: PRIVILEGE_NAME[PRIVILEGE.restricted],
        value: PRIVILEGE.restricted
      }
    ]);

    const selectValue = computed({
      get: () =>
        options.value.find(option => option.value === props.modelValue),
      set: option => emit('update:modelValue', option?.value)
    });

    watchEffect(() => {
      if (props.accountPrivilege === PRIVILEGE.admin) {
        options.value = options.value.filter(option =>
          [PRIVILEGE.member, PRIVILEGE.restricted].includes(option.value)
        );
      }
    });

    return {
      selectValue,
      options
    };
  }
});
