import { WorkspaceApi } from '@/api';
import { PAYMENT_TYPE, PRIVILEGE } from '@/common/constant';
import { trimDate } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import useModal from '@/composables/modal';
import { AclRule } from '@/plugins/acl';
import { useAcl } from '@/store/acl';
import { Ref, ref } from 'vue';
import { fetchAccountAvatar } from './fetchImage';

type UserItem = {
  id?: number;
  userdata: {
    name: string;
    isPrimaryOwner: boolean;
    email?: string;
    imageUrl?: string;
  };
  profile?: string;
  privilege?: { userId?: number; privilege?: string };
  date?: string;
  button: { id?: number; privilege?: string };
};

type GetWorkspaceUsersArgs = {
  privilegeList?: string[];
  offset?: number;
  limit?: number;
  keyword?: string;
};

interface UseWorkspaceUsersReturnTypes {
  userListHeaders: { id: string; label: string; width?: string }[];
  userListItems: Ref<UserItem[]>;
  totalCount: Ref<number>;
  workSpaceUserSearchText: Ref<string>;
  isRemoveModalOpen: Ref<boolean>;
  removeUserName: Ref<string>;
  canRemoveUser: (privilege: string) => boolean;
  openRemoveModal: (userId: number) => void;
  removeUser: () => void;
  restoreToPrivilege: (userId: number) => void;
  getWorkspaceUsers: ({
    privilegeList,
    offset,
    limit,
    keyword
  }: GetWorkspaceUsersArgs) => void;
}

export const useWorkspaceUsers = (
  workspaceId: number
): UseWorkspaceUsersReturnTypes => {
  const userListItems = ref<UserItem[]>([]);
  const totalCount = ref<number>(0);
  const workSpaceUserSearchText = ref('');

  const getWorkspaceUsers = async ({
    privilegeList,
    offset,
    limit,
    keyword
  }: GetWorkspaceUsersArgs = {}) => {
    if (!keyword || !keyword.match(/\S/g)) keyword = undefined;
    const isOwner = privilegeList?.length
      ? privilegeList?.includes(PRIVILEGE.owner)
      : false;
    const privilegePrimaryOwner = isOwner;
    const privilegeOwner = isOwner;
    const privilegeManager = privilegeList?.includes(PRIVILEGE.admin);
    const privilegeMember = privilegeList?.includes(PRIVILEGE.member);
    const privilegeRestricted = privilegeList?.includes(PRIVILEGE.restricted);
    const privilegeFreeOwner = privilegeList?.includes(PRIVILEGE.freeOwner);
    const privilegeFreeMember = privilegeList?.includes(PRIVILEGE.freeMember);

    const users = await WorkspaceApi.getWorkspacesWorkspaceIdAccountsSearch(
      workspaceId,
      offset,
      limit,
      keyword,
      privilegePrimaryOwner,
      privilegeOwner,
      privilegeManager,
      privilegeMember,
      privilegeRestricted,
      privilegeFreeOwner,
      privilegeFreeMember
    );
    const list = users.data.list ?? [];
    const count = users.data.totalCount ?? 0;

    userListItems.value = list.map(user => ({
      id: user.id,
      userdata: {
        name: user.nickname || `${user.lastName} ${user.firstName}`,
        isPrimaryOwner: user.accountPrivilege === PRIVILEGE.primary,
        email: user.email
      },
      profile: user.accountDescription,
      privilege: {
        userId: user.id,
        userName: user.nickname || `${user.lastName} ${user.firstName}`,
        privilege: user.accountPrivilege,
        isPrimaryOwner: user.accountPrivilege === PRIVILEGE.primary
      },
      date: trimDate(user.lastActiveDateTime),
      button: {
        id: user.id,
        privilege: user.accountPrivilege
      }
    }));
    totalCount.value = count;

    for (const user of userListItems.value) {
      if (user.id) {
        fetchAccountAvatar(user.id.toString()).then(imageData => {
          if (!imageData) return;
          user.userdata.imageUrl = imageData;
        });
      }
    }
  };

  const { isModalOpen: isRemoveModalOpen, openModal, closeModal } = useModal(
    false
  );
  const acl = useAcl();
  const canRemoveUser = (privilege: string) => {
    if (acl.can(new AclRule(PRIVILEGE.owner))) {
      return [PRIVILEGE.admin, PRIVILEGE.member, PRIVILEGE.restricted].includes(
        privilege
      );
    } else if (acl.can(new AclRule(PRIVILEGE.admin))) {
      return [PRIVILEGE.member, PRIVILEGE.restricted].includes(privilege);
    } else if (acl.can(new AclRule(PRIVILEGE.freeOwner))) {
      return [PRIVILEGE.freeMember].includes(privilege);
    } else {
      return acl.can(new AclRule(PRIVILEGE.primary));
    }
  };
  const removeUserId = ref<number>(0);
  const removeUserName = ref('');
  const openRemoveModal = (userId: number) => {
    removeUserId.value = userId;
    removeUserName.value =
      userListItems.value.find(item => item.id === removeUserId.value)?.userdata
        .name || '';
    openModal();
  };
  const removeUser = async () => {
    try {
      if (typeof removeUserId.value === 'undefined') return;
      await WorkspaceApi.deleteWorkspacesWorkspaceIdAccountsAccountId(
        workspaceId,
        removeUserId.value
      );
      userListItems.value = userListItems.value.filter(
        user => user.id !== removeUserId.value
      );
      closeModal();
      toast({
        title: '削除完了',
        message: '指定のユーザーを削除しました',
        variant: 'success'
      });
      totalCount.value = totalCount.value - 1;
    } catch (error) {
      toast({
        title: '削除失敗',
        message: '指定のユーザーの削除に失敗しました',
        variant: 'error'
      });
    }
  };
  const restoreToPrivilege = (userId: number) => {
    userListItems.value = userListItems.value.map(user => ({
      ...user,
      privilege: {
        ...user.privilege,
        privilege:
          userId === user.id ? PRIVILEGE.primary : user.privilege?.privilege
      }
    }));
  };

  getWorkspaceUsers({
    privilegeList: [
      acl.can(new AclRule(PAYMENT_TYPE.paid)) ? PRIVILEGE.owner : '',
      acl.can(new AclRule(PAYMENT_TYPE.paid)) ? PRIVILEGE.admin : '',
      acl.can(new AclRule(PAYMENT_TYPE.paid)) ? PRIVILEGE.member : '',
      acl.can(new AclRule(PAYMENT_TYPE.paid)) ? PRIVILEGE.restricted : '',
      acl.can(new AclRule(PAYMENT_TYPE.free)) ? PRIVILEGE.freeOwner : '',
      acl.can(new AclRule(PAYMENT_TYPE.free)) ? PRIVILEGE.freeMember : ''
    ],
    offset: undefined,
    limit: undefined,
    keyword: undefined
  });

  return {
    userListHeaders: [
      { id: 'userdata', label: 'ユーザー' },
      { id: 'profile', label: 'プロフィール' },
      { id: 'privilege', label: '権限', width: '180px' },
      { id: 'date', label: '最終アクティブ' },
      { id: 'button', label: '', width: '12px' }
    ],
    userListItems,
    totalCount,
    workSpaceUserSearchText,
    removeUserName,
    isRemoveModalOpen,
    getWorkspaceUsers,
    canRemoveUser,
    openRemoveModal,
    removeUser,
    restoreToPrivilege
  };
};

export const usePrivilegeSelect = (
  workspaceId: string
): {
  canChangePrivilege: (privilege: string) => boolean;
  changePrivilege: (userId: number, privilege: string) => void;
} => {
  const acl = useAcl();
  const changePrivilege = async (userId: number, privilege: string) => {
    await WorkspaceApi.putWorkspacesWorkspaceIdAccountsAccountIdEdit(
      Number(workspaceId),
      userId,
      {
        accountPrivilege: privilege
      }
    );
  };
  const canChangePrivilege = (privilege: string) => {
    if (acl.can(new AclRule(PRIVILEGE.owner))) {
      return [PRIVILEGE.admin, PRIVILEGE.member, PRIVILEGE.restricted].includes(
        privilege
      );
    } else if (acl.can(new AclRule(PRIVILEGE.admin))) {
      return [PRIVILEGE.member, PRIVILEGE.restricted].includes(privilege);
    } else {
      return acl.can(new AclRule(PRIVILEGE.primary));
    }
  };

  return {
    canChangePrivilege,
    changePrivilege
  };
};
