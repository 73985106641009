<template>
  <Card title="ユーザー管理">
    <Flex align-center justify-end>
      <template
        v-if="
          userInfoStore.currentWorkspace(workspaceId).paymentType === 'FREE'
        "
      >
        <label v-ripple class="privilege-checkbox-label">
          <Checkbox
            v-model="checked"
            :value="PRIVILEGE.freeOwner"
            @change="
              getWorkspaceUsers({
                keyword: workSpaceUserSearchText,
                privilegeList: checked
              })
            "
          />
          <PrivilegeLabel :privilege="PRIVILEGE.freeOwner" class="ml-2" />
        </label>
        <label v-ripple class="privilege-checkbox-label">
          <Checkbox
            v-model="checked"
            :value="PRIVILEGE.freeMember"
            @change="
              getWorkspaceUsers({
                keyword: workSpaceUserSearchText,
                privilegeList: checked
              })
            "
          />
          <PrivilegeLabel :privilege="PRIVILEGE.freeMember" class="ml-2" />
        </label>
      </template>

      <template v-else>
        <label v-ripple class="privilege-checkbox-label">
          <Checkbox
            v-model="checked"
            :value="PRIVILEGE.owner"
            @change="
              getWorkspaceUsers({
                keyword: workSpaceUserSearchText,
                privilegeList: checked
              })
            "
          />
          <PrivilegeLabel :privilege="PRIVILEGE.owner" class="ml-2" />
        </label>
        <label v-ripple class="privilege-checkbox-label">
          <Checkbox
            v-model="checked"
            :value="PRIVILEGE.admin"
            @change="
              getWorkspaceUsers({
                keyword: workSpaceUserSearchText,
                privilegeList: checked
              })
            "
          />
          <PrivilegeLabel :privilege="PRIVILEGE.admin" class="ml-2" />
        </label>
        <label v-ripple class="privilege-checkbox-label">
          <Checkbox
            v-model="checked"
            :value="PRIVILEGE.member"
            @change="
              getWorkspaceUsers({
                keyword: workSpaceUserSearchText,
                privilegeList: checked
              })
            "
          />
          <PrivilegeLabel :privilege="PRIVILEGE.member" class="ml-2" />
        </label>

        <label v-ripple class="privilege-checkbox-label">
          <Checkbox
            v-model="checked"
            :value="PRIVILEGE.restricted"
            @change="
              getWorkspaceUsers({
                keyword: workSpaceUserSearchText,
                privilegeList: checked
              })
            "
          />
          <PrivilegeLabel :privilege="PRIVILEGE.restricted" class="ml-2" />
        </label>
      </template>
    </Flex>
    <Flex justify-space-between class="mt-2">
      <Flex align-baseline>
        <Typography size="xxxl" bold>
          <span v-digit3>{{ totalCount }}</span>
        </Typography>
        <Typography class="ml-1">users</Typography>
      </Flex>
      <Box width="442px">
        <SearchTextField
          v-model="workSpaceUserSearchText"
          placeholder="名前／メールアドレス"
          size="s"
          @click="
            getWorkspaceUsers({
              keyword: workSpaceUserSearchText,
              privilegeList: checked
            })
          "
        />
      </Box>
    </Flex>
    <DataList
      :headers="userListHeaders"
      :items="userListItems"
      :highlight-ids="[loginUser.id]"
      spacing="s"
      class="mt-6"
    >
      <template v-slot:userdata="{ item }">
        <Flex align-center>
          <Box width="64px">
            <AccountIcon size="m" :image-url="item.imageUrl" />
          </Box>
          <div class="ml-2">
            <Flex align-center>
              <Typography bold line-height="1.5">{{ item.name }}</Typography>
            </Flex>
            <Typography size="s" line-height="1.5">{{ item.email }}</Typography>
          </div>
        </Flex>
      </template>

      <template v-slot:profile="{ item: description }">
        <Typography size="s" color="grey" line-height="1.5">
          <div class="description-line-clamp">{{ description }}</div>
        </Typography>
      </template>

      <template v-slot:privilege="{ item }">
        <template
          v-if="
            loginUser.id !== item.userId &&
              canChangePrivilege(item.privilege) &&
              userInfoStore.currentWorkspace(workspaceId).paymentType !== 'FREE'
          "
        >
          <PrivilegeSelect
            v-model="item.privilege"
            @change="
              target => {
                target.value === PRIVILEGE.primary
                  ? confirmChange(item.userId, item.userName, item.privilege)
                  : changePrivilegeHandler(
                      item.userId,
                      item.privilege,
                      target.value
                    );
              }
            "
            :account-privilege="
              userInfoStore.currentWorkspace(workspaceId)?.accountPrivilege
            "
          />
        </template>
        <template v-else>
          <PrivilegeLabel :privilege="item.privilege" />
        </template>
      </template>

      <template v-slot:button="{ item }">
        <template
          v-if="loginUser.id !== item.id && canRemoveUser(item.privilege)"
        >
          <IconButton
            icon-name="close-circle"
            @click="openRemoveModal(item.id)"
          />
        </template>
        <template v-else>{{ '' }}</template>
      </template>
    </DataList>
  </Card>
  <RemoveModal
    v-model="isRemoveModalOpen"
    title="ユーザーの削除"
    :target="removeUserName"
    deleteMessage="さんをワークスペースから削除します"
    @remove="removeUser"
  />
  <Modal
    v-model="isChangeModalOpen"
    title="プライマリーオーナーの譲渡"
    button-label="譲渡する"
    @click-button="
      () => {
        changePrivilege(changeUser.userId, changeUser.privilege);
        closeChangeModal();
      }
    "
    @close="restoreToPrivilege(changeUser.userId)"
  >
    <Flex justify-center>
      <Typography size="l" line-height="1.5">
        このワークスペースのプライマリーオーナー権限を<br />
        「<span class="change-user-name">{{ changeUser.name }}</span
        >」へ譲渡します
      </Typography>
    </Flex>
    <div class="change-user-warning mt-6">
      <Typography size="l" line-height="1.5">
        ・譲渡後は通常のオーナーとなります
      </Typography>
      <Typography size="l" line-height="1.5">
        ・譲渡後は他のオーナーの権限を変更することができなくなります
      </Typography>
    </div>
  </Modal>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  useWorkspaceUsers,
  usePrivilegeSelect
} from '@/composables/workspaceUsers';
import { useUserInfoStore } from '@/store/userInfo';
import { PRIVILEGE } from '@/common/constant';
import useCheckBox from '@/composables/checkbox';
import useModal from '@/composables/modal';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import AccountIcon from '@/components/ui/AccountIcon.vue';
import Card from '@/components/ui/Card.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import DataList from '@/components/ui/DataList.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Modal from '@/components/ui/Modal.vue';
import PrivilegeLabel from '@/components/ui/PrivilegeLabel.vue';
import PrivilegeSelect from '@/components/ui/PrivilegeSelect.vue';
import RemoveModal from '@/components/ui/RemoveModal.vue';
import SearchTextField from '@/components/ui/SearchTextField.vue';
import Typography from '@/components/ui/Typography.vue';
import { toast } from '@/components/ui/Toast';

export default defineComponent({
  name: 'WorkspaceUsers',
  components: {
    AccountIcon,
    Box,
    Card,
    Checkbox,
    DataList,
    Flex,
    IconButton,
    Modal,
    PrivilegeLabel,
    PrivilegeSelect,
    RemoveModal,
    SearchTextField,
    Typography
  },
  setup() {
    const {
      params: { workspaceId }
    } = useRoute();

    const userInfoStore = useUserInfoStore();

    const {
      userListHeaders,
      userListItems,
      totalCount,
      getWorkspaceUsers,
      removedUserName,
      isRemoveModalOpen,
      canRemoveUser,
      openRemoveModal,
      removeUserName,
      removeUser,
      restoreToPrivilege,
      workSpaceUserSearchText
    } = useWorkspaceUsers(workspaceId);

    const { canChangePrivilege, changePrivilege } = usePrivilegeSelect(
      workspaceId
    );

    const { checked } = useCheckBox();
    if (userInfoStore.currentWorkspace(workspaceId).paymentType === 'FREE') {
      checked.value = [PRIVILEGE.freeOwner, PRIVILEGE.freeMember];
    } else {
      checked.value = [
        PRIVILEGE.owner,
        PRIVILEGE.admin,
        PRIVILEGE.member,
        PRIVILEGE.restricted
      ];
    }

    const {
      isModalOpen: isChangeModalOpen,
      openModal: openChangeModal,
      closeModal: closeChangeModal
    } = useModal();

    const changeUser = ref({ userId: '', name: '', privilege: '' });
    const confirmChange = (userId, name, privilege) => {
      changeUser.value = { ...changeUser.value, userId, name, privilege };
      openChangeModal();
    };

    const changePrivilegeHandler = async (
      userId,
      beforePrivilege,
      afterPrivilege
    ) => {
      try {
        await changePrivilege(userId, afterPrivilege);
      } catch (e) {
        if (e.type === 'error' && e.status === 400) {
          toast({ title: '失敗', message: e.message, variant: 'error' });
          // 変更前の状態に権限を戻す
          userListItems.value.find(
            v => v.privilege.userId === userId
          ).privilege.privilege = beforePrivilege;
        } else {
          throw e;
        }
      }
    };

    return {
      PRIVILEGE,
      userInfoStore,
      workspaceId,
      loginUser: userInfoStore.user,
      userListHeaders,
      userListItems,
      totalCount,
      checked,
      workSpaceUserSearchText,
      canChangePrivilege,
      changePrivilege,
      confirmChange,
      isChangeModalOpen,
      closeChangeModal,
      changeUser,
      restoreToPrivilege,
      getWorkspaceUsers,
      removedUserName,
      isRemoveModalOpen,
      canRemoveUser,
      openRemoveModal,
      removeUserName,
      removeUser,
      changePrivilegeHandler
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';

.privilege-checkbox-label {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: $spacing-base * 4;
  }
}

.change-user-name {
  font-weight: 700;
}

.change-user-warning {
  padding: $spacing-base * 4;
  background-color: var(--primary-light-color);
  border-radius: 4px;
}

.description-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}
</style>
